
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.account-page {
  @include for-size(phone-portrait-down) {
    padding-top: 20px;
  }

  &__logout {
    display: none;
    @include for-size(phone-portrait-down) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      background: rgba(148, 102, 255, 0.1);
      color: #9466FF;
    }
  }
}
.account-page-tab {
  &__title {
    margin-bottom: 22px;

    @include for-size(phone-portrait-down) {
      margin-bottom: 16px;
    }
  }

  &__pagination {
    padding: math.div($gap, 2) 0;
    display: flex;
    justify-content: center;
  }

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 22px;

    @include for-size(phone-portrait-down) {
      margin-bottom: 16px;
    }
  }

  &__settings {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__settings-hint {
    margin-top: 12px;
  }

  &__item {
    transition:
      width $base-animation,
      margin-left $base-animation,
      background-color $base-animation,
      color $base-animation !important;

    &:before {
      content: '=';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -$draggable-element-width;
      font-size: 26px;
      visibility: hidden;
      opacity: 0;
      transition: opacity $base-animation;
    }
    &--draggable {
      width: calc(100% - #{$draggable-element-width});
      margin-left: $draggable-element-width;
      position: relative;
      cursor: move !important;

      &:before {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
